import React, { FC, useState } from 'react';
import classnames from 'classnames';
import {
  Container,
  Row,
  Col,
  CardDeck,
  CardGroup,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardFooter,
  Button,
} from 'reactstrap';
import styles from './index.module.scss';

import Truncate from 'react-truncate';
import Image from 'next/image';
import { useRouter } from 'next/router';

import CardsImage from '../../Cards/CardsImage/CardsImage';
import DateTime from '../../../components/Cards/CardsDateTime/CardsDateTime';
import PDFBlob from '@bt-react/components/PDFBlob';

// Libraries
import gridUTMParams from '../../../libs/gridUTMParams';
import RemoveWatchCardButton from '../../RemoveWatchCardButton/RemoveWatchCardButton';
import RemoveWatchLaterCard from '../../RemoveWatchLaterCard/RemoveWatchLaterCard';
import CardsFooterButtonsContainer from '../../CardsFooterButtonsContainer/CardsFooterButtonsContainer';
import WatchLaterButtons from '../../WatchLaterButtons/WatchLaterButtons';
import getTalkImageSrc from '../../../libs/getTalkImageSrc';

import sanitize from '@bt-react/libs/sanitize';

// Default placeholder image
const testlogo = '/portal/placeholders/test-logo.png';

interface listGrids {
  gridItemName?: string; // What are all the grid item names?@hugo? Lets change this to string literal type
  listStyle?: string; // Can we change this to string literal type as well.
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  footLinkTitle: string;
  companyLogo?: boolean;
  imgHoverOverlay?: boolean;
  gridData: Communication[];
  flexDirection?:
    | 'flex-row'
    | 'flex-reverse-row'
    | 'flex-reverse-column'
    | 'flex-column';
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  utmParams?: string;
  setLiveLabel?: boolean;
  envUrl: string;
  priorityStatus?: boolean;
  certificateLink?: boolean;
  pageType?: string;
  showTime?: boolean;
}

export interface Communication {
  id: number;
  channel: Channel[];
  title: string;
  description: string;
  keywords: string;
  presenter: string;
  status: 'live' | 'upcoming' | 'recorded' | 'processing';
  scheduled: Date;
  entryTime: Date;
  closeTime: Date;
  created: Date;
  lastUpdated: Date;
  duration: number;
  start: Date;
  timeZone: string;
  format: string;
  publishStatus: string;
  visibility: string;
  links: Link[];
  url: string;
  channelWebcastUrl: string;
  rating: number;
  totalViewings: number;
  uniqueViewers: number;
  registrations: number;
  ratingCount: number;
}

export interface Channel {
  id: number;
  link: Link[];
}

export interface Link {
  href: string;
  rel: string;
  type: string;
  title: string;
}

// This gets called on every request
export async function getServerSideProps(context) {
  // Pass data to the page via props
  return {
    props: {
      envUrl: global.process.env.HOST,
    },
  };
}

const TalksGridItems: FC<listGrids> = ({
  gridItemName,
  listStyle,
  listLayout,
  cardLayout,
  gridData,
  flexDirection,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  footLinkTitle,
  companyLogo,
  imgHoverOverlay,
  utmParams,
  setLiveLabel,
  envUrl,
  priorityStatus,
  certificateLink,
  pageType,
  showTime = true,
}) => {
  {
    /*
GridData talks keys:
keys {id, channel, title, description, keywords, presenter, status, scheduled,
     entryTime, closeTime, created, lastUpdated, duration, start, timeZone,
     format, publishStatus, visibility, links, url, channelWebcastUrl, rating,
     totalViewings, uniqueViewers, registrations, ratingCount}

 We pass:
 1. We pass in talk status on the col element to identify the talk as upcoing/sheduled or recorded.
 2. talk status to the footer dateTime comp to switch between display formats.
*/
  }

  const router = useRouter();

  let utmFromUrl;

  utmFromUrl = router.asPath.split('?')?.[1];

  if (utmFromUrl) {
    utmFromUrl = '?' + utmFromUrl;
  }

  const Data = gridData;

  function getChannelLogo(channel) {
    let channelLogo = '/portal/placeholders/brand-logo.svg';
    channel &&
      [channel].map(({ link }) => {
        link &&
          [link].map(({ href, title }) => {
            if (title === 'logo') {
              channelLogo = href;
            }
          });
      });
    return channelLogo;
  }

  // set a image hover class to enable switching off styles
  // Only really want to set this class if we DO NOT want hover
  // otherwise we might have pass props for all other grid calls.
  let imgHoverOverlayClass = '';
  if (imgHoverOverlay === false) {
    imgHoverOverlayClass = 'no-img-hover';
  } else if (imgHoverOverlay === true) {
    imgHoverOverlayClass = '';
  }

  {
    /*
  Where we call talks grid but seperate the grids by status we need to be careful
  Not to show live labels  on a upcoming talk in error but ALWAYS show live label on live block
  APF-2078 hotfix.
 */
  }
  function showLiveLabel(status, setLiveLabel) {
    let showLiveLabel = false;
    let setStatus = '';

    if (setLiveLabel === false) {
      showLiveLabel = false;
    } else if (setLiveLabel === true) {
      showLiveLabel = true;
      setStatus = 'live'; // 'setStatus' is assigned a value but never used. @hugo can we look at this.??
    } else if (status === 'live') {
      showLiveLabel = true;
    }
    //console.log(showLiveLabel);
    return showLiveLabel;
  }

  {
    /*
  If we have manually forced display of live labels -as above - we should
  also ensure that the live status class is set as live
  in case status hasn't changed/updated otherwise we won't position element as visible.
 */
  }
  function setStatus(status, setLiveLabel) {
    let setStatus = '';

    if (setLiveLabel === true) {
      setStatus = 'live';
    } else {
      setStatus = status;
    }
    return setStatus;
  }

  return (
    <>
      <Row
        className={classnames(styles[listLayout], 'g-4')}
        data-bdd="talks-grid"
      >
        {Data?.map(
          (
            {
              id,
              channelWebcastUrl,
              title,
              description,
              links,
              presenter,
              url,
              status,
              channel,
              start,
              duration,
            },
            index
          ) => (
            <Col
              sm={rowColSm}
              md={rowColMd}
              lg={rowColLg}
              xl={rowColXl}
              className={classnames(
                styles['column']
                // setHideClassForBtn(clickedId, index)
              )}
              id={`TalksColId-` + index}
              key={`TalksCol-` + id + index}
              data-talk-type={status}
              data-section-name={gridItemName + '-' + index}
            >
              <CardDeck
                key={`CardDeck-` + id + index}
                className={classnames('h-100')}
              >
                <Card
                  key={`Card-` + id + index}
                  className={classnames(
                    styles['list-item'],
                    styles[gridItemName],
                    styles[cardLayout],
                    styles[listStyle],
                    styles[setStatus(status, setLiveLabel)],
                    'h-100',
                    styles[imgHoverOverlayClass],
                    flexDirection
                  )}
                >
                  <a
                    className={classnames(
                      'stretched-link',
                      styles['link-img-hover']
                    )}
                    key={`pop-talks-item` + id + index}
                    href={
                      envUrl +
                      '/webcast/' +
                      channel['id'] +
                      '/' +
                      id +
                      gridUTMParams(
                        utmFromUrl !== undefined ? utmFromUrl : utmParams,
                        gridItemName,
                        index + 1
                      )
                    }
                    aria-label={'View ' + title}
                  ></a>

                  <div
                    key={`img-wrap` + id + index}
                    className={styles['img-wrap']}
                  >
                    {showLiveLabel(status, setLiveLabel) && (
                      <span className={styles['live-label']}>LIVE NOW</span>
                    )}

                    <CardsImage
                      key={`CardsImage-` + id + index}
                      id={id}
                      href={getTalkImageSrc(links)}
                      title={title}
                      gridType={'webcasts'}
                      priorityStatus={priorityStatus}
                    />
                  </div>

                  <CardBody
                    key={`cardbody-` + id + index}
                    className={styles['cardbody']}
                  >
                    {companyLogo == true &&
                      (gridItemName === 'pop-talks-item' ||
                        gridItemName === 'talks-grid-knowledge-feed') && (
                        <div
                          key={`companyLogo` + id + index}
                          className={styles['body-comp-logo']}
                        >
                          <Image
                            key={`channelLogo-` + id + index}
                            src={getChannelLogo(channel)}
                            quality={80}
                            alt={'Channel Logo'}
                            width="30"
                            height="30"
                          />
                        </div>
                      )}

                    <div
                      key={`main-body` + id + index}
                      className={styles['main-body']}
                    >
                      <CardTitle
                        tag="h2"
                        className={styles['item-title']}
                        key={`item-title` + id + index}
                        data-bdd={'talks-title'}
                      >
                        <Truncate
                          key={`Truncate` + id + index}
                          lines={2}
                          title={sanitize(title)}
                        >
                          {sanitize(title)}
                        </Truncate>
                      </CardTitle>

                      {gridItemName === 'talks-grid-search' && (
                        <CardText className={classnames(styles['presenter'])}>
                          {companyLogo == true && (
                            <div
                              key={`companyLogo` + id + index}
                              className={styles['body-comp-logo']}
                            >
                              <Image
                                key={`channelLogo-` + id + index}
                                src={getChannelLogo(channel)}
                                quality={80}
                                alt={'Channel Logo'}
                                width={30}
                                height={30}
                              />
                            </div>
                          )}

                          <span className={styles['presenter-span']}>
                            {presenter}
                          </span>
                        </CardText>
                      )}

                      <CardText
                        tag="p"
                        className={classnames(styles['card-description'])}
                        key={`card-description` + id + index}
                        data-bdd={'talks-card-description'}
                      >
                        <Truncate
                          key={`Truncate` + id + index}
                          lines={3}
                          title={sanitize(description)}
                        >
                          {sanitize(description)}
                        </Truncate>
                      </CardText>

                      <CardText
                        key={`item-meta` + id + index}
                        tag="p"
                        className={styles['item-meta']}
                      >
                        <DateTime
                          key={`DateTime` + id + index}
                          id={`DateTime-` + id}
                          status={status}
                          dateTime={start}
                          showTime={showTime}
                          fromType="talk"
                          duration={duration}
                        />

                        {footLinkTitle != '' && (
                          <a href={url} key={`footLinkTitle` + id + index}>
                            {footLinkTitle}
                          </a>
                        )}
                      </CardText>

                      {pageType === 'recently-viewed' ||
                      pageType === 'watch-later' ? (
                        <CardsFooterButtonsContainer id={id} index={index}>
                          {certificateLink === true &&
                            pageType === 'recently-viewed' && (
                              <PDFBlob
                                webcastId={channel['id'] + '-' + id}
                                fromPage={'recently-viewed'}
                              />
                            )}

                          {pageType === 'watch-later' && (
                            <WatchLaterButtons
                              url={
                                url +
                                gridUTMParams(
                                  utmFromUrl ? utmFromUrl : utmParams,
                                  gridItemName,
                                  index + 1
                                )
                              }
                              index={index}
                              channel={channel}
                              id={id}
                            />
                          )}
                        </CardsFooterButtonsContainer>
                      ) : (
                        ''
                      )}
                    </div>
                  </CardBody>
                </Card>
              </CardDeck>
            </Col>
          )
        )}
      </Row>
    </>
  );
};

export default TalksGridItems;
