import handleErrorResponse from '../handleErrorResponse';
import swrUrlParser from '../swrUrlParser/swrUrlParser';

async function getFetch(url, params = {}, cache = 3600, BTSESSION?: string) {
  let response;

  const fullUrl = swrUrlParser(url, params);

  let cacheTime;
  // Turn off cache for test01 debugging
  if (url.includes('test01')) {
    cacheTime = 0;
  } else {
    cacheTime = cache;
  }

  if (BTSESSION && typeof BTSESSION != 'undefined') {
    try {
      response = await fetch(fullUrl, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Cookie: 'BTSESSION=' + BTSESSION,
          'X-BrightTALK-access': 'niHeeF1ao2',
          'BrightTALK-API-Client': 'audience-portal-wordpress',
          'Content-Type': 'application/json',
        },
        next: { revalidate: cacheTime },
      });
      response = await response.json();
    } catch (err) {
      handleErrorResponse(fullUrl, err);
    }
  } else {
    try {
      response = await fetch(fullUrl, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-BrightTALK-access': 'niHeeF1ao2',
          'BrightTALK-API-Client': 'audience-portal-wordpress',
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
        next: { revalidate: cacheTime },
      });
    response = await response.json();
    } catch (err) {
      handleErrorResponse(fullUrl, err);
    }
  }

  return response;
}

export default getFetch;
